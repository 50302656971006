<template>
  <b-card
    no-body
    :class="['credential__card', { 'credential__card--error': showError }]"
    id="certificate"
    v-if="courseCredential"
  >
    <b-row no-gutters class="justify-content-center m-0" v-if="!showError">
      <b-col cols="12">
        <b-row no-gutters class="credential__head m-0">
          <b-col cols="12" lg="3" md="3">
            <div class="ch-logo">
              <img
                :src="
                  require(`@/assets/images/logo/whoa-logo-black-${logoLanguage}.svg`)
                "
                alt="WHO Academy"
                height="48"
              />
            </div>
          </b-col>
          <b-col cols="12" lg="6" md="6">
            <div class="ch-badge">
              <img :src="awardIcon" alt="Credential Badge" height="80" />
            </div>
          </b-col>

          <b-col cols="12">
            <div class="ch-title">
              {{ courseCredential?.credential?.title }}
            </div>
          </b-col>
        </b-row>
      </b-col>

      <b-col cols="11">
        <b-row no-gutters class="credential__body">
          <b-col cols="12">
            <div class="cb__title">
              {{ $t("achievements.microcredential.message") }}
            </div>
          </b-col>
          <b-col cols="12">
            <div class="cb__name">
              {{
                courseCredential.user
                  ? courseCredential.user.name
                  : courseCredential.name
              }}
            </div>
          </b-col>
          <b-col cols="12" class="cb__details-background">
            <b-row no-gutters class="cb__details m-0">
              <b-col cols="auto" lg="4" class="text-left">
                <div class="cb__details-content">
                  <img
                    :src="require(`@/assets/images/certificates/valid-for.svg`)"
                    alt="valid-for"
                  />
                  <span class="cb__content-title">
                    {{
                      courseCredential.created_at | formatDate("DD MMMM, YYYY")
                    }}
                  </span>
                </div>
              </b-col>
              <b-col cols="auto" lg="8" class="text-left">
                <div class="cb__content-description valid-for">
                  {{ $t("achievements.microcredential.valid_for") }}
                  {{
                    $t("achievements.microcredential.x_years_other", {
                      count: courseCredential?.credential?.valid_for
                    })
                  }}
                </div>
              </b-col>
            </b-row>
            <b-row
              no-gutters
              class="cb__details m-0"
              v-for="(s, i) in sections"
              :key="i"
            >
              <b-col cols="12" lg="4" class="text-left">
                <div class="cb__details-content">
                  <img :src="s.icon" alt="criteria" />
                  <span class="cb__content-title"> {{ s.name }} : </span>
                </div>
              </b-col>
              <b-col cols="12" lg="8" class="text-left">
                <div class="cb__content-description">
                  {{ courseCredential.credential[s.key] }}
                </div>
              </b-col>
            </b-row>
          </b-col>
          <b-col cols="12" class="cb-disclaimer--div">
            <div class="cb-disclaimer">
              <img
                :src="require(`@/assets/images/certificates/disclaimer.svg`)"
                alt="badge"
                width="12"
                height="13"
              />
              <span>
                {{ $t("achievements.microcredential.disclaimer.content") }}
              </span>
            </div>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <b-row no-gutters class="justify-content-center" v-else>
      <b-col cols="7">
        <b-row no-gutters class="credential__body">
          <b-col cols="12">
            <div class="mt-5">
              <img
                :src="require(`@/assets/images/certificates/warning.svg`)"
                alt="download"
                width="54"
                height="49"
              />
            </div>
            <div class="cb__error-title">
              {{ $t("my_achievements.error.message") }}
            </div>
            <div class="mb-5">
              <LxpButton
                variant="outline-primary"
                size="default"
                className="cb-back-btn"
                block
                @click="$router.go(-1)"
              >
                {{ $t("general.go_back") }}
              </LxpButton>
            </div>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </b-card>
</template>
<script>
import { mapGetters } from "vuex";
import { LxpButton } from "didactica";
import getLogo from "@/core/mixins/getLogo";

export default {
  name: "CertificateCard",
  components: { LxpButton },
  mixins: [getLogo],
  props: {
    showError: {
      type: Boolean,
      required: true
    },
    courseCredential: Object
  },
  data() {
    return {
      awardIcon: require("@/assets/images/course-details/award.svg"),
      sections: [
        {
          name: this.$t("achievements.microcredential.admission_criteria"),
          icon: require(`@/assets/images/certificates/admission-criteria.svg`),
          key: "admission_criteria"
        },
        {
          name: this.$t("achievements.microcredential.module_requirements"),
          icon: require(`@/assets/images/certificates/module-requirements.svg`),
          key: "learning_requirements"
        },
        {
          name: this.$t("achievements.microcredential.module_description"),
          icon: require(`@/assets/images/certificates/module-description.svg`),
          key: "section_description"
        },
        {
          name: this.$t("achievements.microcredential.module_activities"),
          icon: require(`@/assets/images/certificates/module-activities.svg`),
          key: "learning_task"
        },
        {
          name: this.$t("achievements.microcredential.module_outcomes"),
          icon: require(`@/assets/images/certificates/module-outcomes.svg`),
          key: "learning_outcomes"
        },
        {
          name: this.$t("achievements.microcredential.module_assessment"),
          icon: require(`@/assets/images/certificates/module-assessment.svg`),
          key: "level_of_performance"
        },
        {
          name: this.$t("achievements.microcredential.evidence_of_achievement"),
          icon: require(`@/assets/images/certificates/module-outcomes.svg`),
          key: "evidence_of_achievement"
        }
      ],
      key: ""
    };
  },
  computed: {
    ...mapGetters(["getAuthProfile"])
  },
  mounted() {
    this.key = this.$route.query.key;
    setTimeout(() => {
      this.$i18n.locale = this.language;
      this.$store.commit("SET_LANGUAGE", this.language);
    }, 50);
  }
};
</script>
<style lang="scss" scoped>
.credential__card.card {
  background: $brand-neutral-0;
  box-shadow: 0px 1px 3px 1px rgba(33, 33, 33, 0.25);
  border-radius: 8px;
  overflow: hidden;
  min-height: 484px;
  display: flex;
  justify-content: flex-start;
  margin-bottom: 24px;

  &.credential__card--error {
    height: 328px;
  }

  .credential__head {
    background: $brand-secondary2-50;
    padding: 32px 40px 8px;
    border-radius: 8px;

    .ch-badge {
      text-align: center;
    }

    .ch-title {
      @include button-label;
      letter-spacing: 0.15px;
      margin: 21px 0 6px;
      text-align: center;
      color: $brand-neutral-900;
    }

    .ch-subtitle {
      @include subtitle-regular;
      color: $brand-primary-400;
    }
  }

  .credential__head.blue {
    background: $brand-primary-100;
    padding: 32px 40px 8px;

    .ch-title {
      margin-top: 24px;
      color: $brand-primary-400;
    }

    .ch-subtitle {
      @include subtitle-regular;
      color: $brand-primary-400;
    }
  }

  .credential__body.blue {
    .cb__title {
      @include subtitle-large($brand-neutral-900);
    }
    .cb-description {
      @include body-regular;
      text-align: center;
      margin: 16px 0 16px 0;
      color: $brand-neutral-700;
    }

    .cb-issued-date {
      @include label-large($brand-neutral-300, 500);
      align-items: center;
      margin-bottom: 16px;
    }
  }

  .credential__body {
    margin: 0 8px;

    .cb__title {
      @include label-large($brand-neutral-300, 500);
      margin: 8px 0 16px;
      text-align: center;
    }
    .cb__name {
      @include label-large($brand-neutral-700, 500);
      margin-bottom: 24px;
      text-transform: capitalize;
    }

    .cb__details-background {
      background: $brand-primary-75;
      padding: 24px;
      .cb__details {
        align-items: center;
        &:not(:last-child) {
          border-bottom: 1px solid $brand-neutral-200;
        }

        .cb__details-content {
          padding: 8px 8px 8px 0;

          img {
            margin-right: 10px;
          }
        }
      }
    }

    .cb__content-title--validity {
      @include label-small;
      color: $brand-neutral-700;
    }

    .cb__content-title {
      @include label-medium(500);
      line-height: 16px;
      letter-spacing: 0.2px;
      color: $brand-neutral-900;
    }

    .cb__content-description {
      @include label-medium;
      letter-spacing: 0.4px;
      color: $brand-neutral-900;
      padding: 8px 8px;

      &.valid-for {
        color: $brand-neutral-300;
        font-weight: 500;
      }
    }
    .cb__error-title {
      margin: 32px auto;
      @include body-regular($brand-neutral-900);
      font-weight: 500;
      letter-spacing: 0.15px;
      max-width: 358px;
    }
    .cb-back-btn {
      text-transform: capitalize;
      border-width: 1px;
      color: $mylearning_empty_sec_button;
      height: 40px;
      padding: 8px 24px;
      max-width: 280px;
      margin: auto;
    }
  }

  .cb-disclaimer--div {
    margin-top: 80px;
    .cb-disclaimer {
      display: flex;
      align-items: flex-start;
      text-align: left;
      margin: 24px 0 16px;
      @include label-medium(500);
      color: $brand-neutral-300;

      img {
        margin: 3px 10px 0 0;
      }
    }
  }
}

@media screen and (max-width: 990px) {
  .credential__card {
    margin: 40px 16px 100px !important;

    .credential__head {
      background: $brand-secondary2-50;
      padding: 15px 15px 8px;
    }

    .credential__body {
      .cb__title {
        margin-top: 12px;
      }
    }

    .cb__content-description {
      padding: 0px 8px 8px 0px;

      &.valid-for {
        padding: 0;
      }
    }

    .ch-logo {
      text-align: left;
    }
  }
}

[dir="rtl"] {
  .credential__card {
    .cb-disclaimer {
      img {
        margin-right: 0px;
        margin-left: 10px;
      }
    }

    .cb__details-content {
      padding: 8px 8px 8px 0;

      img {
        margin-right: 0px;
        margin-left: 10px;
      }
    }

    .cb__details-content,
    .cb__content-title,
    .cb__content-description,
    .cb-disclaimer {
      text-align: right;
    }
  }
}
</style>
